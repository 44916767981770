import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Компактность",
			desktopTitle: "Скромные габариты",
			desktopContent:
				"Система учета продаж и продуктов всегда будет иметь низкий приоритет при распределении места в фудтраке. С Quick Resto комплект автоматизации более не требует отдельной комнаты для сервера, проводов и драгоценной поверхности для установки кассы в салоне фудтрака.",
			detailLink: pagesLinks.index,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/foodtrack-available-1.png"
					alt="система учёта для фудтрака"
					breakpoints={[440, 740, 920, 660]}
					sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 660px"
					objectFit="cover"
					objectPosition="center 80%"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 536,
			tabName: "Доступность",
			desktopTitle: "Минимальные затраты на оборудование",
			desktopContent:
				"Всё что нужно для начала работы — терминал на планшете для обслуживания гостей и необходимый по закону фискальный регистратор, легко крепятся к потолку и стенам фургона, освобождая место для чего-то более важного.",
			detailLink: pagesLinks.index,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/foodtrack-available-2.png"
					alt="касса на планшете в фудтрак"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Мультиплатформенность",
			desktopTitle: "Мульти-платформенность",
			desktopContent:
				"Программу для фудтраков Quick Resto можно установить на различные платформы операционных систем: кассовый терминал работает на планшетах iOS, системах на ОС Windows и Android, а также на терминалах Эвотор.",
			detailLink: pagesLinks.index,
			mediaContent: (
				<StaticImage
					className={mediaContent.image3}
					src="./assets/foodtrack-available-3.png"
					alt="программа для фудтраков"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
