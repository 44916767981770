import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 490,
			tabName: "Автономность",
			desktopTitle: "Автономность и офлайн режим",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Система автоматизации фудтраков Quick&nbsp;Resto автономна и работает
					в отсутствии интернета и даже электричества. Будь уверен — все продажи
					и&nbsp;списания будут корректно учтены, когда появится связь с
					сервером.
				</p>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/foodtrack-modes-1.png"
					alt="касса для фудтрака"
					breakpoints={[440, 740, 920, 586]}
					sizes="(max-width: 500px) 440px, (max-width: 800px) 740px, (max-width: 1319px) 920px, (min-width: 1320px) 586px"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Режимы",
			desktopTitle: "Режим работы Quick Food",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Специально разработанный интерфейс кассового терминала для
					автоматизации фудтраков и&nbsp;подобных форматов, режим работы
					«заказ-чек». Никаких лишних действий, все ради ускорения процесса
					обслуживания. Режим Quick Food действительно способен разгрузить
					очереди —&nbsp;от&nbsp;выбора блюда до приёма оплаты всего пара
					кликов.
				</p>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/foodtrack-modes-2.png"
					alt="кассовый терминал в фудтраке"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 490,
			tabName: "Типы оплат",
			desktopTitle: "Приём различных типов оплат",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Оплата наличными или картой в несколько действий и последующий
					корректный учёт — это именно то, чего так не хватало малым форматам
					бизнеса и это то, что предлагает программа Quick&nbsp;Resto.
				</p>
			),
			detailLink: pagesLinks.terminal,
			mediaContent: (
				<StaticImage
					className={mediaContent.image}
					src="./assets/foodtrack-modes-3.png"
					alt="кассовая система для фудтрака"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
