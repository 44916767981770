import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import styles from "./styles.module.scss"

import { pagesLinks } from "../../common/links"

export default [
	{
		title: "Облачная программа автоматизации для фудтрака",
		description: (
			<>
				<p>
					Управление продажами в фудтраках и отслеживание финансовых результатов
					теперь не просто удобно, а с Quick&nbsp;Resto это ещё и современно
					и&nbsp;технологично.
				</p>
				<p data-mt="small">
					Единая экосистема объединяет в себе интуитивно понятный кассовый
					терминал на планшете, мобильное приложение с функциями предзаказа и
					push-уведомлений, личный кабинет с полной статистикой продаж и
					аналитикой за прошедшие периоды, система электронной очереди и
					QR-меню.
				</p>
			</>
		),

		img: (
			<StaticImage
				className={styles.image1}
				src="./assets/foodtrack-feature-1.png"
				alt="программа автоматизации фудтрака"
				breakpoints={[440, 575, 810, 568]}
				sizes="(max-width: 500px) 440px, (max-width: 800px) 575px, (max-width: 1319px) 810px, (min-width: 1320px) 568px"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature1,
		theme: "white",
	},
	{
		title: "Удобная CRM система управления фудтраком",
		description: (
			<p className={styles.smallDescription}>
				Расширяй возможности бизнеса с&nbsp;дополнительными приложениями
				и&nbsp;оборудованием: подключи тикет-принтер для кухни, дисплей повара и
				дисплей покупателя, а также экран электронной очереди.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image2}
				src="./assets/foodtrack-feature-2.png"
				alt="мобильное приложение для доставки"
				breakpoints={[270, 500, 366]}
				sizes="(max-width: 500px) 270px, (max-width: 1319px) 500px, (min-width: 1320px) 366px"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature2,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.indexWL.href,
	},
	{
		title: "Программа лояльности, как инструмент для привлечения гостей",
		description: (
			<p>
				Quick Resto поддержит акции, бонусы, абонементы, купоны, спецпредложения
				и другие программы лояльности. Мы бесплатно разработаем мобильное
				приложение для фудтрака, и ты сможешь предложить гостям доставку блюд и
				предзаказ с онлайн-оплатой.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image3}
				src="./assets/foodtrack-feature-3.png"
				alt="программы лояльности для фудтрака"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),
		reverse: true,

		className: styles.feature3,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.marketing.href,
	},
	{
		title: "Показатели всегда под рукой",
		description: (
			<p className={styles.smallDescription}>
				Используйте мобильное приложение Quick&nbsp;Resto Manager для быстрого
				удаленного доступа к статистике работы заведения. Вы сможете оценить
				эффективность программ лояльности, работу сотрудников, прибыльность блюд
				и многое другое.
			</p>
		),

		img: (
			<StaticImage
				className={styles.image4}
				src="./assets/foodtrack-feature-4.png"
				alt="quick resto manager"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		className: styles.feature4,
		theme: "white",

		actionText: "Подробнее",
		actionLink: pagesLinks.indexQRM.href,
	},
	{
		title: "Внедрение системы автоматизации фудтрака под ключ",
		description: (
			<>
				<p>От первой продажи до масштабирования.</p>
				<p>Quick Resto даст все инструменты:</p>
				<ul>
					<li>Приложения для управления и продаж</li>
					<li>Отчёты по продажам, блюдам, сотрудникам</li>
					<li>Складской учёт и технологические карты</li>
					<li>CRM-система и аналитика</li>
					<li>Сервис: установка, настройка, обучение</li>
					<li>Круглосуточная техподдержка</li>
				</ul>
			</>
		),

		img: (
			<StaticImage
				className={styles.image5}
				src="./assets/foodtrack-feature-5.png"
				alt="Алексей Гаврилов"
				objectFit="contain"
				placeholder="blurred"
				quality={90}
			/>
		),

		reverse: true,
		className: styles.feature5,
		theme: "white",

		backdrop: <div className={styles.featureTopRightTrapezeBackdrop} />,
	},
]
