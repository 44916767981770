import React from "react"
import { StaticImage } from "gatsby-plugin-image"

import mediaContent from "./mediaContent.module.scss"
import { pagesLinks } from "../../../pages-data/_V2/common/links"

export default {
	features: [
		{
			desktopContentWidth: 545,
			tabName: "Аналитика",
			desktopTitle: "Комплексный учет и управление фудтраком из любой точки",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Система автоматизации фудтраков Quick&nbsp;Resto позволяет отслеживать
					и анализировать все процессы работы: от приёмки товаров на точке
					продаж до определения самых продаваемых позиций и расчёта зарплат
					сотрудников.
				</p>
			),
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image1}
					src="./assets/foodtrack-accounting-1.png"
					alt="система автоматизации фудтраков"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 545,
			tabName: "Склад",
			desktopTitle: "Складские операции на кассовом терминале",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					В программе учёта Quick&nbsp;Resto можно проводить складские операции
					прямо с кассового терминала: доверь персоналу создание приходных
					накладных и проведение инвентаризаций. Все результаты будут доступны в
					облачном бэк-офисе с любого устройства.
				</p>
			),
			detailLink: pagesLinks.warehouse,
			mediaContent: (
				<StaticImage
					className={mediaContent.image2}
					src="./assets/foodtrack-accounting-2.png"
					alt="складской учет в фудтраке"
					objectFit="cover"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
		{
			desktopContentWidth: 545,
			tabName: "Отчеты",
			desktopTitle: "Увеличивай средний чек и свою прибыль",
			desktopContent: (
				<p className={mediaContent.smallDescription}>
					Отчеты по продажам покажут, какие блюда приносят больше прибыли
					фудтраку. Отчеты по сотрудникам дадут возможность увидеть, кто продаёт
					лучше. Автоматический расчет зарплат добавит прозрачности и мотивации
					для вашего персонала.
				</p>
			),
			detailLink: pagesLinks.reports,
			mediaContent: (
				<StaticImage
					className={mediaContent.image3}
					src="./assets/foodtrack-accounting-3.png"
					alt="abc-анализ"
					objectFit="contain"
					placeholder="blurred"
					quality={90}
				/>
			),
		},
	],
}
